/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CmsBlock from '../CmsBlock';
import { FOOTER_CONTENT } from './Footer.config';

/** @namespace Satisfly/Component/Footer/FooterContent/Component/FooterContent */
export const FooterContent = () => (
    <CmsBlock identifier={ FOOTER_CONTENT } blockType="footer_content" />
);

export default FooterContent;
