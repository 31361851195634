/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    FooterContainer as SourceFooterContainer,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Footer/Footer.container';

/** @namespace Satisfly/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code,
});

/** @namespace Satisfly/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Satisfly/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    static propTypes = {
        ...super.propTypes,
        storeCode: PropTypes.string.isRequired,
    };

    containerProps() {
        const superProps = super.containerProps();
        const { storeCode } = this.props;

        return {
            ...superProps,
            storeCode,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
